var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "v-line" }, [
        _c(
          "div",
          { staticClass: "v-line-items" },
          [
            _vm._l(_vm.childList, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "v-line-item",
                  class: { "v-select": item.salaryItemType == _vm.selectType },
                  on: {
                    click: function ($event) {
                      return _vm.handleType(item.salaryItemType)
                    },
                  },
                },
                [
                  _c("span", { staticClass: "v-line-item-num warningNum" }, [
                    _vm._v(_vm._s(item.perNum)),
                  ]),
                  _c("span", { staticClass: "v-line-item-tit" }, [
                    _vm._v(_vm._s(item.salaryName)),
                  ]),
                ]
              )
            }),
            _vm.selectDataList.length > 1
              ? _c(
                  "div",
                  { staticClass: "buttoms" },
                  _vm._l(_vm.selectDataList.length, function (item, key) {
                    return _c("div", {
                      key: key,
                      staticClass: "buttom",
                      class: key === _vm.code ? "color" : "",
                      on: {
                        click: function ($event) {
                          return _vm.onClick(key)
                        },
                      },
                    })
                  }),
                  0
                )
              : _vm._e(),
          ],
          2
        ),
        _c(
          "div",
          {
            staticClass: "v-line-right",
            class: { "v-select": _vm.selectType == "all" },
            on: {
              click: function ($event) {
                return _vm.handleType(_vm.selectData.salaryItemType)
              },
            },
          },
          [
            _c("span", { staticClass: "v-line-item-num" }, [
              _vm._v(_vm._s(_vm.selectData.perNum)),
            ]),
            _c("span", { staticClass: "v-line-item-title" }, [
              _vm._v(_vm._s(_vm.selectData.salaryName)),
            ]),
          ]
        ),
      ]),
      _c(
        "appForm",
        {
          attrs: {
            "to-list": _vm.toList,
            "screen-roster-list": _vm.screenRosterList,
            width: "400",
          },
          on: {
            getSearchCallback: _vm.getSearchCallback,
            screenRosterParams: _vm.screenRosterParams,
            exportFileList: _vm.exportFileList,
          },
        },
        [
          _vm.selectType !== "all" && _vm.isNotDetail
            ? _c(
                "template",
                { slot: "customButton" },
                [
                  _c(
                    "a-space",
                    [
                      _c(
                        "a-button",
                        {
                          attrs: { prefix: "piliangdaoru" },
                          on: { click: _vm.importTotal },
                        },
                        [_vm._v(" 导入合计 ")]
                      ),
                      ["A02", "A03", "A04", "A05"].includes(_vm.selectType)
                        ? _c(
                            "a-button",
                            {
                              attrs: { prefix: "piliangdaoru" },
                              on: { click: _vm.importdetail },
                            },
                            [_vm._v(" 导入明细 ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
      _c("custom-table", {
        attrs: {
          columns: _vm.columns,
          "data-source": _vm.dataSource,
          "row-key": "onJobId",
          "offset-bottom": 60,
          pagination: {
            current: _vm.pager.pageNum,
            pageSize: _vm.pager.pageSize,
            total: _vm.total,
            showTotal: function (total) {
              return "共 " + total + " 条数据"
            },
            showQuickJumper: true,
            showSizeChanger: true,
          },
        },
        on: { change: _vm.loadDataList },
        scopedSlots: _vm._u([
          {
            key: "staffName",
            fn: function (ref) {
              var scope = ref.scope
              return [
                _c("CbTableAvater", {
                  attrs: {
                    "table-avater": scope.record.avatar,
                    "table-name": scope.record.staffName,
                    "on-job-id": scope.record.onJobId,
                    "staff-id": scope.record.staffId,
                  },
                }),
              ]
            },
          },
          {
            key: "index",
            fn: function (ref) {
              var scope = ref.scope
              return [_c("span", [_vm._v(_vm._s(scope.index + 1))])]
            },
          },
          {
            key: "operation",
            fn: function (ref) {
              var scope = ref.scope
              return [
                _vm.isNotDetail && _vm.selectType !== "all"
                  ? _c("div", { staticClass: "flex-align-center" }, [
                      _c(
                        "div",
                        { staticClass: "details-btn-box" },
                        [
                          _c(
                            "a-button",
                            {
                              attrs: { type: "link" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleEdit(scope)
                                },
                              },
                            },
                            [_vm._v("编辑")]
                          ),
                          _c(
                            "a-button",
                            {
                              attrs: { type: "link" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleDetail(scope)
                                },
                              },
                            },
                            [_vm._v("明细")]
                          ),
                        ],
                        1
                      ),
                    ])
                  : _c(
                      "div",
                      { staticClass: "details-btn" },
                      [
                        _c(
                          "a-button",
                          {
                            attrs: { type: "link" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDetail(scope)
                              },
                            },
                          },
                          [_vm._v("明细")]
                        ),
                      ],
                      1
                    ),
              ]
            },
          },
        ]),
      }),
      _c(
        "Drawer",
        {
          attrs: {
            size: "small",
            title: "编辑" + _vm.title,
            placement: "right",
            "is-mask": false,
            closable: false,
            visible: _vm.compile,
          },
          on: { close: _vm.onClose, onSubmit: _vm.confirm },
        },
        [
          _c(
            "div",
            { staticClass: "content" },
            [
              _c("div", { staticClass: "contentItem" }, [
                _c("div", { staticClass: "label" }, [_vm._v("姓名")]),
                _c("div", { staticClass: "value" }, [
                  _vm._v(_vm._s(_vm.editInfo.staffName)),
                ]),
              ]),
              _c("div", { staticClass: "contentItem" }, [
                _c("div", { staticClass: "label" }, [_vm._v("工号")]),
                _c("div", { staticClass: "value" }, [
                  _vm._v(_vm._s(_vm.editInfo.staffNum)),
                ]),
              ]),
              _vm._l(_vm.editInfo.items, function (item, index) {
                return [
                  _c(
                    "div",
                    { key: "contentItem" + index, staticClass: "contentItem" },
                    [
                      _c("div", { staticClass: "label" }, [
                        _vm._v(_vm._s(item.salaryItemName)),
                      ]),
                      _c(
                        "div",
                        { staticClass: "value" },
                        [
                          _c("CbNumber", {
                            attrs: {
                              min: 0,
                              precision: 2,
                              "decimal-separator": "",
                            },
                            model: {
                              value: item.amount,
                              callback: function ($$v) {
                                _vm.$set(item, "amount", $$v)
                              },
                              expression: "item.amount",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              }),
            ],
            2
          ),
        ]
      ),
      _c(
        "Drawer",
        {
          attrs: {
            size: "small",
            title: _vm.title + "明细",
            placement: "right",
            "is-mask": "",
            "display-btn": "",
            closable: "",
            visible: _vm.detailVisible,
          },
          on: { close: _vm.detailOnClose },
        },
        [
          _c("div", { staticClass: "detailContent" }, [
            _c("div", { staticClass: "header" }, [
              _c(
                "div",
                { staticClass: "avater" },
                [
                  _c("CopyAvater", {
                    attrs: {
                      size: "28",
                      "on-job-id": _vm.detailInfo.onJobId,
                      "avater-name": _vm.detailInfo.staffName,
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "sum" }, [
                _vm._v(
                  _vm._s(_vm._f("stateFormatNum")(_vm.detailInfo.sum)) + "元"
                ),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "detailItems" },
              [
                _vm._l(_vm.detailInfo.types, function (item, index) {
                  return [
                    _c(
                      "div",
                      {
                        key: "detailContentItem" + index,
                        staticClass: "detailContentItem",
                      },
                      [
                        _c("div", { staticClass: "groupTitle" }, [
                          _c("div", { staticClass: "name" }, [
                            _vm._v(_vm._s(item.salaryTypeName)),
                          ]),
                          _c("div", { staticClass: "amount" }, [
                            _vm._v(
                              _vm._s(_vm._f("stateFormatNum")(item.amount)) +
                                "元"
                            ),
                          ]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "groupContent" },
                          [
                            _vm._l(item.items, function (key, groupItemIndex) {
                              return [
                                _c(
                                  "div",
                                  {
                                    key: "groupItem" + groupItemIndex,
                                    staticClass: "groupItem",
                                  },
                                  [
                                    _c("div", { staticClass: "label" }, [
                                      _vm._v(_vm._s(key.salaryItemName)),
                                    ]),
                                    _c("div", { staticClass: "value" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("stateFormatNum")(key.amount)
                                        )
                                      ),
                                    ]),
                                  ]
                                ),
                              ]
                            }),
                          ],
                          2
                        ),
                      ]
                    ),
                  ]
                }),
              ],
              2
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }